
import  {createRouter, createWebHistory}  from 'vue-router';
import { Trans } from '@/plugins/Translation'

function load(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/${component}.vue`)
}

const routes = [{
    path: '/:locale',
    component: load("Default"),
    beforeEnter: Trans.routeMiddleware,
    children: [{
        path: '',
        name: 'Home',
        component: load('Home')
      },
      {
        path: 'success',
        name: 'Success',
        component: load('Success')
      }
    ]
  },
  {
    path: '/',
    redirect: '/en'
  }
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

export default router;