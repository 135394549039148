export default {
  "ATTENTION: without the unique return code, your package cannot be identified.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAŽNJA: bez jedinstvenog povratnog koda, vaš paket ne može biti identificiran."])},
  "Bank account number starts with %s (example: %s).": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj bankovnog računa počinje s %s (primjer: %s)."])},
  "Be sure to include your unique complaint number with the product in the package. Your unique complaint number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obavezno unesite svoj jedinstveni broj pritužbe s proizvodom u paketu. Vaš jedinstveni broj žalbe je"])},
  "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar"])},
  "Damaged product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oštećeni proizvod"])},
  "Greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozdravljanje"])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozdrav!"])},
  "Information is not required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije nisu potrebne"])},
  "No reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez razloga"])},
  "Once your return has been received at our offices, an e-mail will be sent to the address entered in the complaint form where we will inform you about the latest updates on the return process.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakon što vaš povrat bude primljen u našim uredima, e-mail će biti poslan na adresu unesenu u obrascu za pritužbu gdje ćemo vas obavijestiti o najnovijim ažuriranjima o postupku vraćanja."])},
  "Order number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj narudžbe"])},
  "Paid on our website, and not with the delivery service / courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaćeni na našoj web stranici, a ne s uslugom isporuke/kurir"])},
  "Product quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvaliteta proizvoda"])},
  "Product replacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamjena proizvoda"])},
  "Product return procedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postupak vraćanja proizvoda"])},
  "Product return process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postupak vraćanja proizvoda"])},
  "Reason for the complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razlog za prigovor"])},
  "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povrat novca"])},
  "Refund form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrazac za povrat"])},
  "Returning the product is quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraćanje proizvoda je brzo i jednostavno"])},
  "Returning the product is quick and easy.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraćanje proizvoda je brzo i jednostavno"])},
  "STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korak"])},
  "Select type of complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite vrstu pritužbe"])},
  "Send the package to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošaljite paket na"])},
  "Send the package to the following address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošaljite paket na sljedeću adresu:"])},
  "Something went wrong, try again later or contact our support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nešto je pošlo po zlu, pokušajte ponovo kasnije ili kontaktirajte našu podršku"])},
  "Submit the form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji obrazac"])},
  "The bank account number starts with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj bankovnog računa počinje s %s (primjer: %s)."])},
  "The return procedure has just been forwarded to your e-mail address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postupak vraćanja upravo je proslijeđen na vašu e-mail adresu:"])},
  "Together with the product to be returned, enter your unique return code in the package.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zajedno s proizvodom koji treba vratiti, unesite svoj jedinstveni povratni kôd u paket.\n"])},
  "Type of payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrsta plaćanja"])},
  "When we receive the package in our branch, you will be informed about the course of the complaint to your e-mail address, which you provided when submitting the complaint form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kada primimo paket u našoj poslovnici, bit ćete obaviješteni o tijeku prigovora na svoju e-mail adresu koju ste naveli prilikom podnošenja obrasca za pritužbu"])},
  "Wrong product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogrešan proizvod"])},
  "Wrong quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogrešna količina"])},
  "You can print out already completed information to help return the product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možete ispisati već dovršene informacije kako biste vratili proizvod"])},
  "You have already submitted a return request. Be patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Već ste podnijeli zahtjev za povratom. Budite strpljivi"])},
  "You have received a PDF file for return procedure in the attachment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primili ste PDF datoteku za postupak vraćanja u privitku."])},
  "Your unique return number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaš jedinstveni povratni broj je"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grad"])},
  "complaint number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["broj žalbe"])},
  "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRIMJER"])},
  "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime"])},
  "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upute"])},
  "last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezime"])},
  "post code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštanski broj"])},
  "reciever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receptiever"])},
  "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošiljatelj"])}
}