import { createApp }from 'vue';
import App from './App.vue'
// import { Trans } from './plugins/Translation'
import './assets/tailwind.css'
import './assets/main.css'
import router from './router'
import {i18n} from './i18n'



createApp(App)
.use(router)
.use(i18n)
.mount('#app')